class OpalWoocommerceMain {
    constructor() {
        this.initCarousel();
        this.addedToCart();
        this.initWishlist();
        this.initVariableItem();
    }

    initVariableItem(){
        $('.otf-wrap-swatches .swatch').on('click', (event) => {
            event.preventDefault();
            let $item = $(event.currentTarget);
            let $container = $item.closest('.product');

            if($item.hasClass('checked')){
                $container.removeClass('product-swatched');
                $item.removeClass('checked');
                $container.find('.product-image-swatch').remove();
            }else{
                $item.closest('.otf-wrap-swatches').find('.swatch').removeClass('checked');
                $container.addClass('product-swatched product-swatch-loading');
                $item.addClass('checked');
                let object = $item.data('image');
                if(object.src){
                    let $img = $('<img />').on('load', (event) => {
                        $container.find('.product-image-swatch').remove();
                        $container.removeClass('product-swatch-loading');
                        $container.find('.product-image').first().before(`<div class="product-image-swatch">${event.currentTarget.outerHTML}</div>`);
                    }).attr({
                        src: object.src,
                    });
                    if(object.srcset){
                        $img.attr(srcset, object.srcset);
                    }
                }
            }
        })
    }

    initCarousel() {
        $('.woocommerce-carousel').each((index, element) => {
            let $element   = $(element);
            let items      = $element.data('columns');
            let nav        = $element.data('nav');
            let dot        = $element.data('dot');
            let itemMobile = 1;
            let items768   = 2;
            let items980   = items;
            if (items > 3) {
                items980 = items - 1;
                items768 = items - 2;
            }
            if (items < 2) {
                items768 = items;
            }
            $element.find('> .products').owlCarousel({
                items     : items,
                nav       : nav,
                dots      : dot,
                responsive: {
                    0   : {
                        items: itemMobile,
                        dots : false
                    },
                    480 : {
                        items: itemMobile,
                        dots : false
                    },
                    768 : {
                        items: items768
                    },
                    980 : {
                        items: items980
                    },
                    1200: {
                        items: items
                    }
                }
            })
        });
        $('.woocommerce-product-carousel').each((index, element) => {
            let $element   = $(element);
            let items      = $element.data('columns');
            let itemMobile = 1;
            let items768   = 2;
            let items980   = items;
            if (items > 3) {
                items980 = items - 1;
                items768 = items - 2;
            }
            if (items < 2) {
                items768 = items;
            }
            $element.find('ul.products').owlCarousel({
                items     : items,
                nav       : true,
                dots      : false,
                responsive: {
                    0   : {
                        items: itemMobile,
                    },
                    480 : {
                        items: itemMobile,
                    },
                    768 : {
                        items: items768
                    },
                    980 : {
                        items: items980
                    },
                    1200: {
                        items: items
                    }
                }
            })
        });
    }

    addedToCart() {
        let $product;
        $('body').on('adding_to_cart', function (event, button) {
            $product = button.closest('.product');
        }).on('added_to_cart', function () {
            let src         = $product.find('img').first().attr('src');
            let productName = $product.find('.woocommerce-loop-product__title').text();
            let template    = wp.template('added-to-cart-template');
            $('#page-title-bar').after(template({src: src, name: productName}));
            setTimeout(function () {
                $('.notification-added-to-cart').addClass('hide');
            }, 3000);
        });
    }

    initWishlist() {
        $(document).on('added_to_wishlist removed_from_wishlist', function () {
            let $wishlist = $('.opal-header-wishlist');
            $.ajax({
                url       : yith_wcwl_l10n.ajax_url,
                data      : {
                    action: 'otf_update_wishlist_count'
                },
                dataType  : 'json',
                success   : function (data) {
                    $wishlist.find('.count').text(data.count);
                },
                beforeSend: function () {
                },
                complete  : function () {
                }
            })
        })
    }
}

$(document).ready(function () {
    new OpalWoocommerceMain();
});